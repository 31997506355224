import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";

const reviews = [
  {
    logo: "images/rates_logo.png",
    description:
      "Con tu team usted va a la fija es un equipo de gente seria con carácter y con una actitud Super positiva, analiza muy bien porque usted no está vendiendo más y le brindan maneras diferentes para resolver eso, le hacen ver que vender masivamente no es difícil, es una realidad y que no se necesita mucho tiempo para escalarlo.",
    name: "José Garavito",
    position: "CEO"
  },
  {
    logo: "images/jose_musica.jpg",
    description:
      "Tu team ayudó a mi carrera de maneras imaginables, me brindaron toda la información digital, tecnológica y comercial para desarrollar una carrera sostenible, pude entender la carrera musical como un universo de servicios digitales y creación de audiencias y comunidades que monetizaron mi carrera.",
    name: "Jose música",
    position: "Musico"
  },
  {
    logo: "images/mb_logo.png",
    description:
      "Estoy extremadamente satisfecho con los servicios de Tu team.. Gracias a su equipo de telemarketing, marketing digital y entrenamiento de ventas, he visto un aumento significativo en mis ventas. Su enfoque profesional y estrategias personalizadas han sido clave para mejorar mi negocio. Recomiendo encarecidamente sus servicios a cualquier empresa que busque crecer y mejorar su rendimiento en el mercado.",
    name: "Juan Garavito",
    position: "CEO"
  },
  {
    logo: "images/pear-logo.png",
    description:
      "Con el apoyo de Tu Team, transformé mi carrera de freelancer a dueña de una agencia de mercadeo exitosa. Me ayudaron a construir una estructura de negocio basada en porcentajes que asegura que no hay forma de perder dinero. Pasé de tener un solo empleado a contar con un equipo de 15 personas, lo que me permitió dejar mi trabajo corporativo y dedicarme por completo a mi propio negocio. Su orientación y estrategias fueron clave para alcanzar este nivel de éxito.",
    name: "Mandre Lascurain",
    position: "CEO de Creative Pear Agency"
  },
  {
    logo: "images/doctork-logo.png",
    description: "Muchas gracias a Tu Team Marketing y Ventas. Con su gran trabajo afianzamos aún más nuestro alcance nacional en salud capilar a muchos pacientes, conociendo nuestro trabajo y recibiendo de forma bidireccional todos los beneficios.",
    name: "Kevin Gutierrez",
    position: "Doc. en Doctork"
  }
  // Add more review objects as needed
];

const Reviews = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <section id="reviews" className="reviews-container d-md-block d-none">
      <div className="bg-blur-right w-75">
        <img src="images/review-blur-right.png" alt="blur-bg" />
      </div>
      <div className="bg-blur-right2">
        <img
          src="images/review-blur-right2.png"
          alt="blur-bg"
          className="w-100"
        />
      </div>
      <div className="bg-blur-left w-75">
        <img src="images/review-blur-left.png" alt="blur-bg" />
      </div>
      <div className="bg-blur-left2">
        <img
          src="images/review-blur-left2.png"
          alt="blur-bg"
          className="w-100"
        />
      </div>


      <div className="reviews-container flex-column justify-center align-items-center">
        <h1 className="fw-semibold" style={{fontSize: '3em'}}>Casos de <span className="gradient-text">éxito</span></h1>
        <div className="slider-container2 mt-5">
          <div className="slider-wrapper2">
            <Slider {...settings} className="review-slider">
              {reviews.map((review, index) => (
                <div key={index} >
                    <div className="review-slide">
                    <img
                        src={review.logo}
                        alt={`Logo ${index + 1}`}
                        className="review-logo mb-5"
                    />
                    <p className="review-description">{review.description}</p>
                    <div className="text-start">
                        <h5 className="review-name fw-bold">{review.name}</h5>
                        <p className="review-position">{review.position}</p>
                    </div>
                    </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
